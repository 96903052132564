<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #b5e4f4"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-20 pt-15">
          <a href="#" class="text-center mb-3">
            <img
              src="/img/logo/nimbangbalita2.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <!-- Menjadikan Sekolah Menengah Kejuruan yang berwawasan lingkungan dalam membangun insan yang cerdas dan kompetitif. -->
          <h5
            class="font-weight-light text-center font-size-h4 font-size-h6-lg font-weight-bolder mb-6"
            style="color: #4c7dbd"
          >
          <!-- style="color: #986923" -->
            Generasi Sehat, Indonesia Unggul
          </h5>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <!-- <div class="row"> -->
                  <div class="d-flex align-items-center">
                    <div class="col-md-4 pr-0">
                      <img
                      src="/img/logo/nimbangbalita2.png"
                      alt="LOGO"
                      height="140"
                    /></div>
                    <div class="col-md-12 pl-3 ml-3">
                        <!-- <span style="color: #3672b7" class="d-block">Sistem Mo<b style="color: #2c67ab;">ni</b>toring & Tu<b style="color: #2c67ab;">m</b>buh Kem<b style="color: #2c67ab;">bang</b> Balita </span> -->
                     <span class="d-block  font-size-h3 text-primary font-weight-bold">
                       Sistem Informasi Posyandu Elektronik
                     </span>
                     <span class="d-block text-primary font-size-h3 font-weight-bold">
                       Nimbang Balita
                     </span>
                    </div>
                  </div>
                <!-- </div> -->
                <!-- <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  <p style="text-align: justify">
                    <img
                      src="/img/login/SMKN4_bersih.png"
                      style="float: left; margin: 0 9px 3px 0"
                      alt="LOGO"
                      width="140"
                    /><span class="d-block">Learning</span> <span class="d-block">Management</span> <span>System</span>
                  </p>
                </h3> -->
                <!-- <span class="text-muted font-weight-bold font-size-h4"
                  >New Here?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  ></span
                > -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >NIK</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <!-- <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    style="color: #93d8f7 !important"
                    >Forgot Password ?</a
                  > -->
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <div class="input-group mb-2">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    :type="passwordIsVisible"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                  <div class="input-group-prepend" @click="passwordIsVisible == 'password' ? passwordIsVisible = 'text' : passwordIsVisible = 'password'">
                    <div class="input-group-text" style="border: none; cursor: pointer;">
                      <template v-if="passwordIsVisible == 'password'">
                        <b-icon-eye-slash></b-icon-eye-slash>
                      </template>
                      <template v-if="passwordIsVisible == 'text'">
                        <b-icon-eye></b-icon-eye>
                      </template>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  style="background-color: #93d8f7; border-color: #93d8f7; color: #3672b7"
                >
                  Sign In
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Nama"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <!-- <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div> -->
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-10 py-4 my-3 ml-2"
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-5"
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5" style="color: #93d8f7 !important"
            >Terms</a
          > -->
          <!-- <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5" style="color: #93d8f7 !important"
            >Plans</a
          > -->
          <!-- <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5" style="color: #93d8f7 !important"
            >Contact Us</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      passwordIsVisible: 'password'
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        // process.env.BASE_URL + "media/svg/illustrations/login-visual-5.svg"
        process.env.BASE_URL + "img/login/login-cover-1.jpeg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "NIK",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password wajib diisi",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Nama harus diisi",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email harus diisi",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password harus diisi",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password harus diisi",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Konfirmasi password dan password harus sama",
            },
          },
        },
        // agree: {
        //   validators: {
        //     notEmpty: {
        //       message: "You should agree terms and conditions",
        //     },
        //   },
        // },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      // this.fv.validate();
      SwalLoading.fire()

      // this.fv.on("core.form.valid", () => {
        var email = this.form.email;
        var password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        // const submitButton = this.$refs["kt_login_signin_submit"];
        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        // submitButton.disabled = true

        // dummy delay
        setTimeout(() => {
          // send login request
          this.$store
            .dispatch(LOGIN, { email, password })
            // go to which page after successfully login
            .then(() => {
              let access_right, role_right;
              ApiService.get("api/users/me").then((data) => {
                // //("user/me", data)
                // ApiService.put(`api/users/${data.data.data.id}/set-user-on`).then((response)=>{
                //   //('success set login')
                // }).catch((error) => {
                //   //('error set login')
                // })
                ApiService.get("api/users/" + data.data.data.id).then(
                  (response) => {
                    //("user access", response);
                    if (data.data.data) {
                      ApiService.get(
                        "api/roles/" + data.data.data.role_id
                      ).then((result) => {
                        //("role access", response);
                        console.log('result', result)
                        access_right = response.data.meta.access_right_display;
                        role_right = result.data.meta.access_right_display;


                        let merge = [...access_right, ...role_right]
                        // //("merge", merge)

                        window.localStorage.setItem(
                          "access_right_display",
                          JSON.stringify(merge)
                        );

                        let user = data.data.data;
                        // user.access_right = access_right + ", " + role_right;
                        JwtService.setUser(JSON.stringify(user));

                        this.$router.push({ name: "dashboard" });
                        SwalLoading.close();
                      });
                    } else if (!data.data.data.position_id) {
                      window.localStorage.setItem(
                        "access_right",
                        response.data.meta.access_right
                      );

                      window.localStorage.setItem(
                        "access_right_display",
                        JSON.stringify(response.data.meta.access_right_display)
                      );

                      let user = data.data.data;
                      access_right = response.data.meta.access_right;
                      user.access_right = response.data.meta.access_right;
                      JwtService.setUser(JSON.stringify(user));

                      for (let z = 0; z < 20; z++) {
                        if (z == 19) {
                          this.$router.push({ name: "dashboard" });
                          SwalLoading.close();
                        }
                      }
                    }
                  }
                );
              });
              // this.$router.push({ path: "dashboard" })
              // SwalLoading.close()
            });
          // submitButton.classList.remove(
          //   "spinner",
          //   "spinner-light",
          //   "spinner-right"
          // );
        }, 100);
      // });

      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false,
      //   });
      // });
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.$refs.remail.value;
        const password = this.$refs.rpassword.value;
        const name = this.$refs.fullname.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              name: name,
              password: password,
            })
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
  },
};
</script>
